<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warehouseId" clearable>
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index"
                               :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryParams.companyId" placeholder="输入客户名称" 
                           remote :remote-method="companyRemote"
                           clearable filterable>
                    <el-option v-for="(item, index) in companyOption" :label="item.companyName" :value="item.companyId" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        :editable="false"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        v-model="queryParams.createTime"
                        placeholder="请选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" :size="$store.getters.size" v-loading="loading" ref="table" row-key="guid" border>
            <el-table-column label="客户名称" prop="companyName" width="300"></el-table-column>
            <el-table-column label="日期" prop="createTime" width="160"></el-table-column>
            <el-table-column label="回框" prop="returnBasketNum" width="100" align="center"></el-table-column>
            <el-table-column label="出框" prop="leaveBasketNum" width="100" align="center"></el-table-column>
            <el-table-column label="待回收框" prop="remainBasketNum" width="100" align="center"></el-table-column>
            <el-table-column label="回豆腐板" prop="returnPlankNum" width="100" align="center"></el-table-column>
            <el-table-column label="出豆腐板" prop="leavePlankNum" width="100" align="center"></el-table-column>
            <el-table-column label="待回收豆腐板" prop="remainPlankNum" width="100" align="center"></el-table-column>
        </el-table>
        <page-item :total="total" ref="pageItem"/>
    </div>
</template>

<script>
    import PageItem from '@/components/PageItem/'
    import {getWarehouseCompany} from '@/api/delivery/delivery'
    import {getCompanyContainer} from '@/api/delivery/delivery'

    export default {
        name: "CompanyContainer",
        components: {PageItem},
        data(){
            return {
                queryParams: {
                    warehouseId: undefined,
                    companyId: undefined,
                    createTime: undefined
                },
                warehouseOption: this.$store.getters.warehouse,
                companyOption: [],
                tableData: [],
                total: 0,
                loading: false
            }
        },
        methods: {
            /** 获取列表数据 */
            getTableData(){
                if (!this.queryParams.warehouseId){
                    this.$message.error("请选择仓库");
                    return;
                }
                if (!this.queryParams.companyId){
                    this.$message.error("请输入公司名称");
                    return;
                }
                if (!this.queryParams.createTime){
                    this.$message.error("请选择查询日期");
                    return;
                }
                this.loading = true;
                this.queryParams.size = this.$refs['pageItem'].pageSize;
                this.queryParams.current = this.$refs['pageItem'].current;
                getCompanyContainer(this.queryParams).then(({code, data})=>{
                    this.loading = false
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                })
            },
            /** 模糊查询仓库下的公司列表 */
            companyRemote(query){
                if (query) {
                    this.loading = true;
                    setTimeout(() => {
                        getWarehouseCompany({warehouseId: this.queryParams.warehouseId, query: query}).then(({code, data})=>{
                            this.loading = false
                            if(code === 0){
                                this.companyOption = data;
                            }
                        })
                    }, 500);
                } else {
                    this.companyOption = [];
                }
            },
            /** 查询 */
            querySearch(){
                this.$refs['pageItem'].current = 1;
                this.getTableData();
            }
        }
    }
</script>